import React from 'react';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
import writersData from '../../data/rebelwriters/gutenberg.json'
import swarmState from '../../data/rebelwriters/adjective_swarm_state.json'
import scrollama from 'scrollama'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

class AdjectiveSwarm extends React.Component {

  constructor(props) {
    super(props);

    const initialState = swarmState.chapters[0].state

    this.state = {
      nodeSize: 25,
      currentIndex: 0,
      windowHeight: 800,
      isLastStepVisible: true,
      ...initialState
    }

    this.resize = this.resize.bind(this);
    this.renderColor = this.renderColor.bind(this);
    this.exploreChartClicked = this.exploreChartClicked.bind(this);
  }

  componentDidMount() {
    this.resize();

    const scroller = scrollama();

    scroller
      .setup({
        step: ".step",
        threshold: 8,
        offset: 0.75,
        debug: false
      })
      .onStepEnter(response => {
        const chapter = swarmState.chapters.find(c => c.id === response.element.id);
        if (!chapter) return

        this.setState({
          ...chapter.state
        })

        trackCustomEvent({
          category: 'RebelWriters',
          action: 'EnteredSwarmStep',
          label: chapter.id
        });
      })
      .onStepExit(response => {
        if (response.index === 0 && response.direction === 'up') {
          const chapter = swarmState.chapters.find(c => c.id === "init");
          this.setState({
            ...chapter.state
          });
        }
      });

    window.addEventListener('resize', this.resize)

  }

  resize() {
    this.setState({
      windowHeight: window.innerHeight
    })

    if (window.innerWidth <= 600) {
      this.setState({
        nodeSize: 14
      })
    } else if (window.innerWidth <= 800) {
      this.setState({
        nodeSize: 16
      })
    } else {
      this.setState({
        nodeSize: 18
      })
    }
  }

  renderColor(node) {
    if (!this.state.highlightedAuthor) {
      return node.data.color;
    } else {
      // if (node.data.author === this.state.highlightedAuthor) {
      if (this.state.highlightedAuthor.includes(node.data.author)) {
        return node.data.color;
      } else {
        return '#BFBFBF'
      }
    }
    // return node.data.color;
  }

  exploreChartClicked() {
    this.setState({
      isLastStepVisible: false,
      highlightedAuthor: null,
      annotations: []
    })

    trackCustomEvent({
      category: 'RebelWriters',
      action: 'ExploreSwarmChartClicked',
    });
  }

  render() {
    return (
      <div>
        <section className='adjectiveSwarm' style={{ height: this.state.windowHeight * 0.75, top: this.state.windowHeight * 0.01 }}>
          <div className='container adjectiveSwarmText'>
            <header className="fn fl-ns w-75-ns pr4-ns pl4-ns">
              <h1 className="mb3 mt0 lh-title">Who uses the most adjectives?</h1>
              <time className="f6 tracked gray">At first, we look at each book's average number of adjectives per sentence</time>
            </header>
          </div>

          <div className="chartContainer">
            <ResponsiveSwarmPlot
              id='adjectiveSwarm'
              data={writersData}
              groups={this.state.authors}
              groupBy='author'
              value='adj_per_sentence'
              layout='horizontal'
              identity='book'
              // colors={{ 'scheme': 'category10' }}
              colors={this.renderColor}
              label={node => node.data.book + ' (' + node.data.author + ') '}
              tooltip={data => (
                <p style={{ color: '#373C3E', backgroundColor: '#F1F3F4', maxWidth: 200, padding: 4, borderRadius: '0.2rem' }}>
                  <span style={{ fontSize: 18 }}> {data.node.data.book} </span> <br />
                  <span style={{ fontSize: 14 }}> {data.node.data.author} </span> <br />
                  <span style={{ fontSize: 14 }}>{data.node.data.adj_per_sentence} adjectives/sentence </span> <br />
                  {/* <span> todo remove {data.node.index}</span> */}
                </p>
              )}
              size={this.state.nodeSize}
              spacing={4}
              forceStrength={8}
              enableGridY={false}
              margin={this.state.margin}
              // axisLeft={null}
              axisRight={null}
              axisTop={{
                orient: 'top',
                tickSize: 0,
                legend: '(Touch or hover over a book for details)',
                legendPosition: 'middle',
                legendOffset: -20,
                format: () => null
              }}
              valueScale={{ type: 'linear', min: 0, max: 3.5, tickValues: 4 }}
              axisBottom={{
                orient: 'bottom',
                tickSize: 1,
                tickPadding: 1,
                tickRotation: 0,
                legend: '# Adjectives per Sentence',
                legendPosition: 'middle',
                legendOffset: 46,
                tickValues: 5,
              }}
              axisLeft={this.state.leftAxis}
              annotations={this.state.annotations}
              theme={{
                axis: {
                  legend: {
                    text: {
                      fontSize: '14px',
                      fontFamily: 'Roboto Slab'
                    }
                  }
                }
              }}
            />
          </div>
        </section>
        <section>
          {/* for some reason this white border is needed, otherwise the layout messes up */}
          <article style={{ border: '1px solid white' }}>
            <div className="step" id="intro-step" style={{ marginTop: this.state.windowHeight * 0.1 }}>
              <p>On average, writers use <u>1.6 adjectives</u> per sentence.</p>
            </div>
            <div className="step" id="bookAnnotations">
              <p>However, two books immediately stand out: Herman Melville's <span style={{ backgroundColor: "#F38C7E" }}>Typee</span> with 3+ adjectives per sentence and James Joyce's <span style={{ backgroundColor: "#C596FC" }}>Ulysses</span> with 0.9.</p>
            </div>
            <div className="step" id="authorsSplit1" style={{ borderTop: '3px solid #C596FC', borderBottom: '3px solid #C596FC' }}>
              <p>But Ulysses is not an exception, as James Joyce is by definition a modern writer. He averages only <b><u>1.2 adjectives per sentence</u></b> on his works.</p>
            </div>
            <div className="step" id="authorsSplitDosto" style={{ borderTop: '3px solid #FBDB8D', borderBottom: '3px solid #FBDB8D' }}>
              <p>Although he didn't write in English, <span style={{ backgroundColor: "#FBDB8D" }}>Dostoyevsky</span> also appears in the modern camp, averaging <b><u>1.3 adjectives per sentence</u></b>. In his most important work, Crime and Punishment, he only used 1.1 adjectives for each sentence he composed.</p>
            </div>

            <div className="step" id="authorsSplitRebels">
              <p>On the rebellious side, we see <span style={{ backgroundColor: "#FEB7FF" }}>Oscar Wilde</span> with 1.7 adjectives per sentence, and <span style={{ backgroundColor: "#939DFB" }}>Jane Austen</span> with 2 adjectives in her sentences.</p>
            </div>

            <div className="step" id="authorsSplit2" style={{ borderTop: '3px solid #F38C7E', borderBottom: '3px solid #F38C7E' }}>
              <p>But by far the most elaborate of the lot appears to be <span style={{ backgroundColor: "#F38C7E" }}>Herman Melville</span>. In his magnum opus, Moby Dick he uses 2.4 adjectives per sentence, while he averages more than 2.5 across his works!</p>
            </div>
            <div className={this.state.isLastStepVisible ? 'step' : 'hidden step'} id="laststepswarm" style={{ borderTop: '3px solid #FB97B5', borderBottom: '3px solid #FB97B5', marginBottom: this.state.windowHeight * 0.75 }}>
              <p><span style={{ fontWeight: 'bolder' }}>Did <span style={{ backgroundColor: "#FB97B5" }}>Mark Twain</span> follow his own advice?</span> Sort of. Compared to his peers, he is an average adjective user, with 1.48 per sentence. The only exception is his historical novel The Prince and the Pauper, which averages 2 adjectives per sentence. </p>
              <div className="exploreChartButton">
                <button style={{ width: '100%' }} type="button" className="f6 link dim ba bw1 ph3 pv2 mb2 dib black" onClick={this.exploreChartClicked}>Explore the chart</button>
              </div>
              <p style={{ fontSize: '0.8rem', textAlign: 'center' }}><i>Keep scrolling for the rest of the story.</i></p>
            </div>
            {/* <div className="step" style={{ height: '5rem', border: '3px solid green' }}></div> */}
          </article>
        </section>
      </div>

    );
  }
}

export default AdjectiveSwarm;