import React from 'react';
import SEO from "../components/seo"
import HeaderCentered from '../components/Header/HeaderCentered'
import '../styles/rebel-writers.css'
import AdjectiveSwarm from '../components/RebelWriters/AdjectiveSwarm';
import AdjectiveScatter from '../components/RebelWriters/AdjectiveScatter';
import SubscribeBox from '../components/SubscribeBox/SubscribeBox';
import rebelWritersSocial from '../images/rebel-writers/rebel-writers-social.png'
import ShareButtons from '../components/ShareButtons/ShareButtons';
import ArticleRecommender from '../components/ArticleRecommender/ArticleRecommender'
import ArticleHeader from '../components/Article/ArticleHeader';
import ArticleParagraph from '../components/Article/ArticleParagraph';


class RebelWriters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 'init',
            authors: [''],
        }
    }

    render() {
        return (
            <div className='rebelWriters'>
                <SEO
                    title="Rebel Writers | An Interactive Story"
                    description="We analyzed the writing style of some of the greatest works of the 19th century to identify the most verbose, flowery, rule-breaking classic authors"
                    pathname='/rebel-writers'
                    image={{ src: rebelWritersSocial, width: 1200, height: 600 }} />

                <HeaderCentered />

                <article className="cf ph3 ph5-ns pv4">
                    <ArticleHeader
                        title='Rebel Writers'
                        author='Dimitris Michailidis'
                        date='July 29, 2020' />
                    <div className="fn fl-ns w-50-l center">
                        <ArticleParagraph text='In a letter to one of his students, Mark Twain describes the "modern way of writing English" as using plain, simple language, short words, and brief sentences.' />
                        <ArticleParagraph text='He appears to be particularly opposed to the use of adjectives, which he describes as "fluff and flowers" that weaken when they are close together.' />
                        <blockquote className="ml0 mt4 mb4 pl4 black-90 bl bw2 b--blue center">
                            <p className="f5 f4-m f3-l lh-copy measure mt0">
                            When you catch an adjective, kill it. No, I don't mean utterly, but kill most of them—then the rest will be valuable.
                            </p>
                            <cite className="f6 ttu tracked fs-normal">Mark Twain</cite>
                        </blockquote>
                        <ArticleParagraph text="We analyzed the writing style of some of the greatest works of the 19th century, with one goal in mind: identify the rebels. Those who, instead of conforming to Twain's modern writing rules, chose their own path of long, dragged out sentences full of flowery additives." />
                        <ArticleParagraph text="Keep scrolling to experience the interactive story." />
                    </div>
                </article>

                <AdjectiveSwarm />

                <AdjectiveScatter />

                <article className="cf ph3 ph5-ns pv4">
                    <div className="fn w-50-l center">
                        <h3 className="f4 lh-title">Methodology</h3>
                        <p className='f5 lh-copy measure mt0-ns center tj'>The authors were selected for their popularity. Texts for the books were downloaded from <a href="https://www.gutenberg.org/" target="_blank" rel="noopener noreferrer">Project Gutenberg</a>. We used the <a href="https://spacy.io/" target="_blank" rel="noopener noreferrer">spacy</a> library to process and analyze the text. Spacy's english model was used to recognize adjectives.</p>

                        <h3 className="f4 lh-title mt5">Enjoyed the story? Subscribe for more:</h3>
                        <SubscribeBox/>
                    </div>

                    <ShareButtons url="https://www.thousandwords.blog/rebel-writers"></ShareButtons>
                    <ArticleRecommender articles={['biggest-concerts', 'world-map-of-greeks',]} />
                </article>
            </div>
        )
    }
}

export default RebelWriters;
