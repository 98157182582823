import React from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import writersDataFile from '../../data/rebelwriters/gutenberg_scatter.json'
import scatterState from '../../data/rebelwriters/adjective_scatter_state.json'
import scrollama from 'scrollama'
import Select from 'react-select'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const authors = [
    { value: "Herman Melville", label: "Herman Melville" },
    { value: "Jane Austen", label: "Jane Austen" },
    { value: "Oscar Wilde", label: "Oscar Wilde" },
    { value: "Robert L. Stevenson", label: "Robert L. Stevenson" },
    { value: "Charles Dickens", label: "Charles Dickens" },
    { value: "Leo Tolstoy", label: "Leo Tolstoy" },
    { value: "Mark Twain", label: "Mark Twain" },
    { value: "Joseph Conrad", label: "Joseph Conrad" },
    { value: "James Joyce", label: "James Joyce" },
    { value: "Fyodor Dostoyevsky", label: "Fyodor Dostoyevsky" },
]

class AdjectiveScatter extends React.Component {
    constructor(props) {
        super(props);

        const initialState = scatterState.chapters[0].state

        this.state = {
            windowHeight: 800,
            writersData: writersDataFile,
            ...initialState
        }

        this.resize = this.resize.bind(this);
        this.renderColor = this.renderColor.bind(this);
        this.exploreChartClicked = this.exploreChartClicked.bind(this);
        this.selectedAuthorChanged = this.selectedAuthorChanged.bind(this);
    }

    componentDidMount() {
        this.resize();

        const scroller = scrollama();

        scroller
            .setup({
                step: ".step",
                threshold: 8,
                debug: false
            })
            .onStepEnter(response => {
                const chapter = scatterState.chapters.find(c => c.id === response.element.id)
                if (!chapter) return

                this.setState({
                    ...chapter.state
                })

                this.setVisibleBooks(this.state.highlightedAuthor)

                trackCustomEvent({
                    category: 'RebelWriters',
                    action: 'EnteredScatterStep',
                    label: chapter.id
                });
            })

        window.addEventListener('resize', this.resize)
    }

    resize() {
        this.setState({
            windowHeight: window.innerHeight
        })
    }

    renderColor(node) {
        return node.color;
    }

    getNodeSize(node) {
        const baseFont = 8;
        const increment = 3;
        // book size
        if (node.words <= 30000) {
            return baseFont;
        } else if (node.words <= 50000) {
            return baseFont + increment;
        } else if (node.words <= 100000) {
            return baseFont + 2 * increment;
        } else if (node.words <= 200000) {
            return baseFont + 3 * increment;
        } else if (node.words <= 300000) {
            return baseFont + 4 * increment;
        } else if (node.words <= 400000) {
            return baseFont + 5 * increment;
        } else if (node.words <= 500000) {
            return baseFont + 6 * increment;
        }
        return 14;
    }

    selectedAuthorChanged(selectedOptions) {
        let selectedAuthors = []
        if (selectedOptions)
            selectedAuthors = selectedOptions.map(o => o.value)
        this.setVisibleBooks(selectedAuthors)

        trackCustomEvent({
            category: 'RebelWriters',
            action: 'SelectedAuthor',
            label: selectedAuthors.toString()
        });
    };

    setVisibleBooks(authors) {
        let writers = writersDataFile;

        if (authors && authors.length > 0) {
            writers = writers.filter(data => authors.includes(data.id))
        }

        this.setState({
            writersData: writers
        })
    }

    exploreChartClicked() {
        this.setState({
            isLastStepVisible: false,
            highlightedAuthor: null,
            annotations: []
        })

        this.setVisibleBooks(null)

        trackCustomEvent({
            category: 'RebelWriters',
            action: 'ExploreScatterChartClicked',
        });
    }

    render() {
        return (
            <div>
                <section className='adjectiveSwarm' style={{ height: this.state.windowHeight * 0.75, top: this.state.windowHeight * 0.01, marginTop: '5rem' }}>
                    <div className='container adjectiveSwarmText'>
                        <header className="fn fl-ns w-75-ns pr4-ns pl4-ns" style={{marginBottom: '10px'}}>
                            <h1 className="mb3 mt0 lh-title">Who is the most rebellious??</h1>
                            <time className="f6 tracked gray">We then rank authors based on the lenght of their sentences and the number of adjectives per sentence.</time>
                        </header>
                        {
                            this.state.selectToolVisible &&
                            <div style={{ maxWidth: '600px', margin: 'auto' }}>
                                <Select
                                    options={authors}
                                    placeholder='filter by author'
                                    isMulti
                                    isSearchable={false}
                                    onChange={this.selectedAuthorChanged}></Select>
                            </div>
                        }

                    </div>
                    <div className="chartContainer">
                        <ResponsiveScatterPlot
                            data={this.state.writersData}
                            xScale={{ type: 'linear', min: 7.5, max: 28 }}
                            yScale={{ type: 'linear', min: 0.5, max: 3.5 }}
                            margin={{ top: 80, right: 50, bottom: 80, left: 60 }}
                            annotations={this.state.annotations}
                            enableGridX={false}
                            enableGridY={false}
                            nodeSize={this.getNodeSize}
                            colors={this.renderColor}
                            blendMode='multiply'
                            /* This will make the tooltip appear only on hover */
                            useMesh={false}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '# of words per sentence',
                                legendPosition: 'end',
                                legendOffset: 46
                            }}

                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '# of adjectives per sentence',
                                legendPosition: 'end',
                                legendOffset: -46
                            }}

                            axisTop={{
                                orient: 'top',
                                tickSize: 0,
                                legend: 'Books are sized by their total words',
                                legendPosition: 'middle',
                                legendOffset: -20,
                                format: () => null
                            }}

                            theme={{
                                axis: {
                                    legend: {
                                        text: {
                                            fontSize: '12px',
                                            fontFamily: 'Roboto Slab'
                                        }
                                    }
                                }
                            }}

                            tooltip={data => (
                                <p style={{ color: '#373C3E', backgroundColor: '#F1F3F4', maxWidth: 200, padding: 4, borderRadius: '0.2rem' }}>
                                    <span style={{ fontSize: 18 }}> {data.node.data.book} </span> <br />
                                    <span style={{ fontSize: 14 }}> {data.node.data.author} </span> <br />
                                    <span style={{ fontSize: 14 }}> {data.node.data.x} words/sentence </span> <br />
                                    <span style={{ fontSize: 14 }}> {data.node.data.y} adjectives/sentence </span> <br />
                                    <span style={{ fontSize: 14 }}>{data.node.data.words} total words </span> <br />
                                    {/* <span> todo remove {data.node.index}</span> */}
                                </p>
                            )}
                        >

                        </ResponsiveScatterPlot>
                    </div>
                </section>
                <section>
                    {/* for some reason this white border is needed, otherwise the layout messes up */}
                    <article style={{ border: '1px solid white' }}>
                        <div className="step" id="init">
                            <p>Herman Melville may be a heavy adjective user, but is he also the biggest rebel? To give a final verdict, we need to explore who follows Twain's second advice: write brief sentences. To evaluate this, we added one more measure to our graph, the average number of words per sentence.</p>
                        </div>
                        <div className="step" id="init-2" style={{ marginTop: this.state.windowHeight * 0.1 }}>
                            <p>At first glance, we notice an interesting pattern. The biggest sentences writers compose, the more adjectives they tend to use.</p>
                        </div>
                        <div className="step" id="standouts">
                            <p><span style={{ backgroundColor: "#F38C7E" }}>Typee</span> and <span style={{ backgroundColor: "#C596FC" }}>Ulysses</span> still stand out as completely opposite structured books.</p>
                        </div>
                        <div className="step" id="singleStyleAuthors">
                            <p>Some authors, like <span style={{ backgroundColor: "#6AE0F7" }}><b>Joseph Conrad</b></span> and <span style={{ backgroundColor: "#CCEFA9" }}><b>Leo Tolstoy</b></span> have a defined style they follow on most of their works.</p>
                        </div>
                        <div className="step" id="diverseAuthors">
                            <p>While others, like <span style={{ backgroundColor: "#939DFB" }}><b>Jane Austen</b></span> and <span style={{ backgroundColor: "#FEB7FF" }}><b>Oscar Wilde</b></span> keep experimenting with their writing style.</p>
                        </div>
                        <div className="step" id="boringAuthors">
                            <p><span style={{ backgroundColor: "#C596FC" }}><b>James Joyce</b></span> and <span style={{ backgroundColor: "#FBDB8D" }}><b>Fyodor Dostoyevsky</b></span> are the de facto modern writers, abiding by Twain's rules of writing short sentences and avoiding adjectives.</p>
                        </div>
                        <div className={this.state.isLastStepVisible ? 'step' : 'hidden step'} id="laststep" style={{ marginBottom: this.state.windowHeight }}>
                            <p>And <span style={{ backgroundColor: "#F38C7E" }}><b>Herman Melville</b></span> proves himself as the biggest rebel, standing out not only for his fluffy adjectives but also for his long, verbose sentences. <br /> Fun fact: by the time Mark Twain sent his letter (1880), Melville was already 61 years old, way past his writing prime and maybe too late to adopt a modernist writing style. Even so, his title as the most rebellious writer is well deserved!
                            </p>
                            <div className="exploreChartButton">
                                <button style={{ width: '100%' }} type="button" className="f6 link dim ba bw1 ph3 pv2 mb2 dib black" onClick={this.exploreChartClicked}>Explore the chart</button>
                            </div>
                        </div>
                    </article>
                </section>
            </div>
        )
    }
}

export default AdjectiveScatter;